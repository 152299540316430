import React from "react"
import styled from "@emotion/styled"

const Content = styled.article`
    min-height: 100%;
    padding: 0 10rem;
    display: flex;
    flex-direction: column;
`

// min-width
const padding = { 1: 1, 375: 1, 667: 2, 768: 2, 1024: 4, 1680: 5, 1920: 10 }
const bpobjs = Object.keys(padding).map(bp => ({
    media: `@media (min-width: ${bp}px)`,
    padding: padding[bp],
}))

const style = bpobjs.reduce((acc, bp) => {
    acc[bp.media] = { padding: `0 ${bp.padding}rem` }

    return acc
}, {})

export default ({ children }) => {
    return (
        <>
            <Content css={style}>{children}</Content>
        </>
    )
}
