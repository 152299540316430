import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const Space = styled.div`
    margin-bottom: 4rem;
`

export default ({ size = 4 }) => {
    return (
        <Space
            css={css`
                margin-bottom: ${size}rem;
            `}
        />
    )
}
