import React from "react"
import styled from "@emotion/styled"
import { css, Global } from "@emotion/core"
import "normalize.css"
import Footer from "../Footer"
import { Helmet } from "react-helmet"

// injects global styles below
const Layout = styled.div`
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const LayoutContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    flex: 1 0 auto;
`

const Container = ({ children }) => {
    return (
        <Layout>
            <Helmet>
                <link
                    rel="icon"
                    href="https://vpletaysya.ru/favicon.ico"
                    type="image/x-icon"
                />
            </Helmet>
            <Global
                styles={css`
                    html {
                        height: 100%;
                    }
                    body {
                        height: 100%;
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: 18px;
                    }
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        text-transform: uppercase;
                        font-family: "Yanone Kaffeesatz", sans-serif;
                        margin: 0;
                    }
                `}
            />
            <LayoutContainer
                css={css`
                    @media (min-width: 1920px) {
                        width: 1920px;
                    }
                `}
            >
                {children}
            </LayoutContainer>
            <Footer />
        </Layout>
    )
}

export default Container
