export const breakPoints = [375, 667, 768, 1024, 1680, 1920]
export const SEO_KEYWORDS = "косички, плетение косичек, причёски, причёски с плетением, причёски из косичек"
export const SEO_DESCRIPTION = "Вплетайся - Ресурс посвящён плетению косичек, а также причёскам из косичек. Содержит фотоуроки и видеоуроки по  плетению кос. Поможет вам научиться плести французскую косу и колосок!"

export const getlessonNodeUrl = lessonNode => {
    let url = ""
    if (lessonNode.url) {
        url = `/lesson/show/id/${lessonNode.url}`
    } else if (lessonNode.old_url) {
        url = lessonNode.old_url // as is
    } else if (lessonNode.old_id) {
        url = `/lesson/show/id/${lessonNode.old_id}`
    }

    return url
}

export const getVideoNodeUrl = video => {
    let url = ""
    if (video.old_link) {
        url = video.old_link
    } else if (video.old_id) {
        url = `/videolesson/show/id/${video.old_id}`
    }

    return url
}

export const extractImageUrlFromPlayerCode = (playerCode = "") => {
    const id = playerCode.match(/(?:embed\/|v=|youtu.be\/)([A-Za-z0-9_-]+)/)

    return id && id[1]
        ? `https://img.youtube.com/vi/${id[1]}/0.jpg`
        : "https://img.youtube.com/vi/0/0.jpg"
}

export const getLessonContentItemData = item => {
    return {
        ...item.node,
        type: "lesson",
        url: getlessonNodeUrl(item.node),
        title: item.node.title,
        text: item.node.text,
        imageUrl: `/upload-files/lesson/${item.node.old_id}/cover/cover_${item.node.old_id}_lesson.jpg`,
    }
}

export const getVideoContentItemData = item => {
    return {
        ...item.node,
        type: "video",
        url: getVideoNodeUrl(item.node),
        text: item.node.text,
        imageUrl: extractImageUrlFromPlayerCode(item.node.player),
    }
}