import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const HeaderContainer = styled.div`
    background-color: white;
    height: 3.063rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2rem;
    @media (max-width: 500px) {
        flex-direction: column;
        height: auto;
        &>div {
            margin-bottom: 0.5rem;
        }
    }
`
const LogoTitle = styled.span`
    font-family: "Yanone Kaffeesatz";
    font-size: 2rem;
    a {
        text-decoration: none;
        color: black;
    }
    @media (max-width: 500px) {
        margin-bottom: 1rem;
    }
`

const MenuItem = styled.span`
    font-family: "Yanone Kaffeesatz";
    font-size: 1.3rem;
    font-weight: 300;
    padding: 1rem;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    color: black;
    &:hover {
        background-color: black;
        color: white;
    }
    @media (max-width: 768px) {
        font-size: 1rem;
        padding: 0.5rem;
    }
`

const menuLinkCss = css`
    text-decoration: none;
    color: black;
`

const Header = () => {
    return (
        <HeaderContainer>
            <LogoTitle>
                <Link to="/">Вплетайся</Link>
            </LogoTitle>
            <div>
                <Link partiallyActive={true} css={menuLinkCss} to="/">
                    <MenuItem>Главная</MenuItem>
                </Link>
                <Link partiallyActive={true} css={menuLinkCss} to="/main/page/all_about_braids">
                    <MenuItem>Статьи</MenuItem>
                </Link>
                <Link partiallyActive={true} css={menuLinkCss} to="/lesson">
                    <MenuItem>Фото-уроки</MenuItem>
                </Link>

                <Link partiallyActive={true} css={menuLinkCss} to="/videolesson">
                    <MenuItem>Видео</MenuItem>
                </Link>
                <Link partiallyActive={true} css={menuLinkCss} to="/faq">
                    <MenuItem>Вопросы</MenuItem>
                </Link>
            </div>
        </HeaderContainer>
    )
}

export default Header
