import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Vk from "../assets/svg/vk.svg"
import Facebook from "../assets/svg/facebook.svg"
import Instagram from "../assets/svg/instagram.svg"
import Odnoklassniki from "../assets/svg/odnoklassniki.svg"
import Youtube from "../assets/svg/youtube.svg"

const Footer = styled.div`
    height: 5rem;
    background-image: linear-gradient(90deg, #377c9e 0%, #d889da 100%);
    width: 100%;
    display: flex;
    justify-content: center;
`

const FooterCenter = styled.div`
    padding: 0 3rem;
    width: 1920px;
    @media (min-width: 1920px - 1) {
        width: 100%;
    }
`

const Icons = styled.div`
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const iconStyle = css`
    width: 24px;
    height: 24px;
    fill: white;
    margin-right: 1rem;
`

export default () => {
    return (
        <Footer>
            <FooterCenter>
                <Icons>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://vk.com/vpletaysya"
                    >
                        <Vk css={iconStyle} />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/groups/447548701967014/"
                    >
                        <Facebook css={iconStyle} />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/olyanaryzhnaya"
                    >
                        <Instagram css={iconStyle} />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://ok.ru/group/56666615054373"
                    >
                        <Odnoklassniki css={iconStyle} />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/4oxygene"
                    >
                        <Youtube css={iconStyle} />
                    </a>
                </Icons>
            </FooterCenter>
        </Footer>
    )
}
